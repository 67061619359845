import React from "react"
import { createClient, Provider } from "urql"
import { useQuery } from "urql"
import store from "store"

const client = createClient({
  url: `${process.env.GATSBY_API_URL}/graphql`,
  fetchOptions: () => {
    return {
      headers: {
        authorization: `Bearer ${store.get("stoken")}`,
      },
    }
  },
})

const URQL_QUERY = `
  query MyQuery {
    qbanks(where: { source: "SBIPO10PC1607031"}) {
      id
      questionId
    }
  }
`

const YourRoutes = () => {
  const [{ data, fetching, error }, reexecuteQuery] = useQuery({
    query: URQL_QUERY,
  })

  if (!fetching) {
    console.log(data)
  }
  if (error) {
    //console.log(error.message)
  }

  return (
    <div>
      {/* {data?.qbanks.map((ques, idx) => (
        <div key={idx}>
          <h2>{ques.questionId}</h2>
          <h3>{ques.topic}</h3>
        </div>
      ))} */}
      hello
      <pre>{JSON.stringify(data, null, 2)}</pre>
    </div>
  )
}

const App = () => (
  <Provider value={client}>
    <YourRoutes />
  </Provider>
)

export default App
